import React from 'react'

export default function CTA({ placeholder = 'Get access to the private beta' }) {
  return (
    <form
      action="https://mythologi.us20.list-manage.com/subscribe/post?u=482ef430df339a97a83814409&amp;id=1e41032073"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
      className="cta"
    >
      <input
        className="cta-input"
        type="email"
        size={30}
        required
        placeholder={placeholder}
        name="EMAIL"
        id="mce-EMAIL"
      />
      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
        <input type="text" name="b_482ef430df339a97a83814409_1e41032073" tabIndex={-1} />
      </div>
      <button className="cta-button" type="submit" name="subscribe" id="mc-embedded-subscribe">
        Sign Up
      </button>
    </form>
  )
}
