import React, { useRef } from 'react'
import { useState } from 'react'

import './homeslides.scss'
import xrImg01 from '../../assets/images/mythologi/xr.jpg'
import artImg01 from '../../assets/images/mythologi/art.jpg'
import strategicImg01 from '../../assets/images/mythologi/strategic.jpg'
import mediaImg01 from '../../assets/images/mythologi/media.jpg'

const slideData = [
  {
    index: 0,
    headline: 'MYTHOLOGI XR',
    src: xrImg01,
  },
  {
    index: 1,
    headline: 'MYTHOLOGI ART + DIGITAL',
    src: artImg01,
  },
  {
    index: 2,
    headline: 'MYTHOLOGI STRATEGIC',
    src: strategicImg01,
  },
  {
    index: 3,
    headline: 'MYTHOLOGI MEDIA',
    src: mediaImg01,
  },
]

type SlideProps = {
  slide: {
    src: string
    headline: string
    index: number
  }
  current: number
  handleSlideClick: (index: number) => void
}

const Slide = (props: SlideProps) => {
  const ref = useRef<HTMLLIElement>()

  const imageLoaded = (event) => {
    event.target.style.opacity = 1
  }

  const { src, headline, index } = props.slide
  const current = props.current
  let classNames = 'slide'

  if (current === index) classNames += ' slide--current'
  else if (current - 1 === index) classNames += ' slide--previous'
  else if (current + 1 === index) classNames += ' slide--next'

  return (
    <li
      ref={ref}
      className={classNames}
      onClick={() => props.handleSlideClick(props.slide.index)}
    >
      <div className="slide__image-wrapper">
        <img className="slide__image" alt={headline} src={src} onLoad={imageLoaded} />
      </div>

      <article className="slide__content">
        <h2 className="slide__headline">{headline}</h2>
      </article>
    </li>
  )
}

const SliderControl = ({ type, title, handleClick }) => {
  return (
    <button className={`btn btn--${type}`} title={title} onClick={handleClick}>
      <svg className="icon" viewBox="0 0 24 24">
        <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
      </svg>
    </button>
  )
}

const Slider = ({ slides, heading }) => {
  const [current, setCurrent] = useState(0)

  const handlePreviousClick = () => {
    const previous = current - 1
    setCurrent(previous < 0 ? slides.length - 1 : previous)
  }

  const handleNextClick = () => {
    const next = current + 1
    setCurrent(next === slides.length ? 0 : next)
  }

  const handleSlideClick = (index) => {
    if (current !== index) {
      setCurrent(index)
    }
  }

  const headingId = `slider-heading__${heading.replace(/\s+/g, '-').toLowerCase()}`
  const wrapperTransform = {
    transform: `translateX(-${current * (100 / slides.length)}%)`,
  }

  return (
    <div className="slider" aria-labelledby={headingId}>
      <ul className="slider__wrapper" style={wrapperTransform}>
        <h3 id={headingId} className="visuallyhidden">
          {heading}
        </h3>

        {slides.map((slide) => {
          return (
            <Slide key={slide.index} slide={slide} current={current} handleSlideClick={handleSlideClick} />
          )
        })}
      </ul>

      <div className="slider__controls">
        <SliderControl type="previous" title="Go to previous slide" handleClick={handlePreviousClick} />
        <SliderControl type="next" title="Go to next slide" handleClick={handleNextClick} />
      </div>
    </div>
  )
}

export default function Homeslider() {
  return <Slider slides={slideData} heading="Brands within Mythologi" />
}
