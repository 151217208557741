import React, { Suspense, useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Fade } from 'react-awesome-reveal'

import Layout from '../components/layout'
import Seo from '../components/plumbing/Head'

import LogoType from '../assets/svg/Mythologi-Full-logo_lighter.svg'
import { Model } from '../components/presentation/Hero'
import { Canvas } from '@react-three/fiber'
import { AdaptiveDpr } from '@react-three/drei'
import Homeslider from '../components/presentation/Homeslider'
import { Link } from 'gatsby'
import CTA from '../components/Mailchimp'
import BGImg1 from '../assets/images/hero_imgs/IMG_0017.jpg'
import BGImg2 from '../assets/images/hero_imgs/IMG_0019.jpg'
import BGImg3 from '../assets/images/hero_imgs/IMG_0020.jpg'
import BGImg4 from '../assets/images/hero_imgs/IMG_0025.jpg'
import BGImg5 from '../assets/images/hero_imgs/IMG_0314.jpg'
import BGImg6 from '../assets/images/hero_imgs/IMG_0315.jpg'
import BGImg7 from '../assets/images/hero_imgs/IMG_0321.jpg'
import BGImg8 from '../assets/images/hero_imgs/IMG_0383.jpg'
import BGImg9 from '../assets/images/hero_imgs/IMG_0384.jpg'
import BGImg10 from '../assets/images/hero_imgs/IMG_0385.jpg'
import BGImg11 from '../assets/images/hero_imgs/IMG_0386.jpg'
import BGImg12 from '../assets/images/hero_imgs/IMG_0387.jpg'
import BGImg13 from '../assets/images/hero_imgs/IMG_0528.jpg'

const images = [
  BGImg1,
  BGImg2,
  BGImg3,
  BGImg4,
  BGImg5,
  BGImg6,
  BGImg7,
  BGImg8,
  BGImg9,
  BGImg10,
  BGImg11,
  BGImg12,
  BGImg13,
]

const IndexPage = () => {
  const [image, setImage] = useState(null)

  useEffect(() => {
    let randomImage = images[Math.floor(Math.random() * images.length)]
    setImage(randomImage)
  }, [])

  return (
    <Layout>
      <Seo title="Mythologi" description="" />
      <img src={image} style={{ width: '100%', position: 'relative', top: '0' }} />
      <div className="mythologi-light-logo">
        <StaticImage src="../assets/images/mythologi-full-logo_lighter.png" width={360} alt="logo" />
      </div>
      <LogoType
        className="ten-central"
        title="Mythologi Logo"
        style={{ marginTop: '1rem', marginBottom: '1rem', zIndex: 1 }}
      />
      <section>
        <Fade className="ten-central v-center" style={{ fontSize: 24 }}>
          <p className="p-wrapper">
            <span style={{ fontSize: 36, marginBottom: '3rem', display: 'block' }}>
              <b>Relational</b> Design + Creative <b>Research</b>
            </span>
            We are a creative research + innovation driven interdisciplinary digital studio.<br></br>
            Our work explores the potential of immersive storytelling in the creation of meaningful
            experiences in the wild.
          </p>
        </Fade>
      </section>

      <section>
        <StaticImage
          src="../assets/images/Valeria_echoes-1.jpg"
          width={1200}
          quality={95}
          formats={['auto', 'webp', 'avif']}
          alt="milky new"
          className="left-half v-center radical"
          style={{ marginBottom: `1.45rem` }}
        />
        <Fade className="right-half v-center" style={{ gridColumn: '8 / span 4' }}>
          <p>
            Create a future where the human experience is woven with data in transparent, ethical, and
            meaningful dialogue.
          </p>
        </Fade>
      </section>
      <section>
        <StaticImage
          src="../assets/images/Kaleidoscope02.jpg"
          width={1200}
          quality={95}
          formats={['auto', 'webp', 'avif']}
          alt="milky new"
          className="right-half radical"
          style={{ marginBottom: `1.45rem` }}
        />
        <div className="left-half v-center" style={{ gridColumn: '2 / span 4' }}>
          <Fade>
            <p>
              Through hypercolored lenses, explore the myriad facets of lived experience by way of our
              relationships and connections.
            </p>
          </Fade>
          <Fade>
            <p>
              Expand mutual understanding and create spaces of reflection in making tangible our relationships
              as living stories.
            </p>
          </Fade>
        </div>
      </section>
      <Fade>
        <StaticImage
          className="elysium-logo-left"
          src="../assets/svg/Elysium_full.svg"
          alt="Elysium logo"
          style={{
            marginBottom: '2rem',
            width: 461,
            height: 140,
            marginLeft: '6rem',
            position: 'relative',
            top: '13.5rem',
          }}
        />
      </Fade>
      <section>
        <Canvas
          className="home-ipad"
          dpr={1.25}
          camera={{ position: [0, 0, 15], near: 5, far: 40 }}
          style={{ gridColumn: '1/ span 5', left: '6rem', top: '-1rem' }}
        >
          <AdaptiveDpr pixelated />
          <ambientLight intensity={0.8} color={'#2c313f'} />
          <directionalLight castShadow position={[2.5, 12, 12]} intensity={4} />
          <pointLight position={[20, 20, 20]} />
          <pointLight position={[-20, -20, -20]} intensity={4} />
          <Suspense fallback={null}>
            <Model />
          </Suspense>
        </Canvas>
        <div className="v-center" style={{ gridColumn: '7 / span 4' }}>
          <Fade>
            <p>
              At the edge of technology and the boundaries of immersive storytelling, we are building ELYSIUM.
            </p>
            <p>
              ELYSIUM is the new landscape for building + engaging deep narrative experiences through
              Augmented Reality.
            </p>
            <div className="powered-by" style={{ fontSize: 32, marginBottom: '3rem' }}>
              Powered by <b>presence</b>. <br></br>Design <b>meaning</b>.
            </div>
            <p>
              <Link to="/elysium" className="cta-input-more">
                Read more
              </Link>
            </p>
          </Fade>
        </div>
      </section>
      <section style={{ gridColumn: '1 / span 12', display: 'block' }}>
        <Homeslider />
      </section>
      <section>
        <div style={{ gridColumn: '2 / span 10' }} className="v-center">
          <Fade>
            <h3>Us</h3>
            <p>
              Ours is a studio of creative interdisciplinary artists brought together by a shared spirit of
              creative innovation.
            </p>
            <p>
              At the intersection of our imagination and relentless technical experimentation, aesthetic
              exploration drives us to push the limits of what is possible at the edge where technology meets
              experience.
            </p>
            <CTA />
          </Fade>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
